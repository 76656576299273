'use strict'

const _ = require('lodash')
const PropTypes = require('prop-types')

function clonePropType(propType) {
    const newPropType = propType.bind(null)
    newPropType.isRequired = propType.isRequired.bind(null)
    return newPropType
}

function createSantaTypesDefinitions(namespace, prefix) {
    return _.reduce(namespace, (acc, val, key) => {
        const path = prefix ? `${prefix}.${key}` : key
        switch (typeof val) {
            case 'function':
                val = clonePropType(val)
                val.id = path
                val.isRequired.id = path
                return _.set(acc, key, val)
            case 'object':
                return _.set(acc, key, createSantaTypesDefinitions(val, path))
            default:
                throw new Error(`wtf:${key}`)
        }
    }, {})
}

const language = PropTypes.shape({
    languageCode: PropTypes.string,
    name: PropTypes.string,
    iconUrl: PropTypes.string
})
const styleShape = PropTypes.shape({
    bottom: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    left: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    position: PropTypes.string,
    right: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    top: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
})

const dockMarginShape = PropTypes.shape({px: PropTypes.number, vw: PropTypes.number})

const columnsContainerChildren = PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    alignment: PropTypes.number.isRequired
}))

module.exports = createSantaTypesDefinitions({
    isInSSR: PropTypes.bool,
    isMobileView: PropTypes.bool,
    isDebugMode: PropTypes.bool,
    isQAMode: PropTypes.bool,
    hideComponentsListForQa: PropTypes.string,
    getRootIdsWhichShouldBeRendered: PropTypes.func,
    isExperimentOpen: PropTypes.func,
    setCustomClickOccurred: PropTypes.func,
    reportBI: PropTypes.func,
    currentUrl: PropTypes.object,
    siteWidth: PropTypes.number,
    rootWidth: PropTypes.string,
    animations: PropTypes.object,
    isSiteBusy: PropTypes.func,
    isPreviewMode: PropTypes.bool,
    isCurrentPageLandingPage: PropTypes.bool,
    santaBase: PropTypes.string,
    RendererModel: {
        multilingual: {
            isEnabled: PropTypes.bool,
            languages: PropTypes.arrayOf(language),
            currentLanguage: language,
            setCurrentLanguageCode: PropTypes.func,
            originalLanguage: language
        }
    },
    currentUrlPageId: PropTypes.string,
    Modes: {
        getActiveModes: PropTypes.func,
        activateModeById: PropTypes.func,
        deactivateModeById: PropTypes.func,
        switchModesByIds: PropTypes.func
    },
    SiteAspects: {
        windowScrollEvent: PropTypes.object,
        windowResizeEvent: PropTypes.object,
        actionsAspect: PropTypes.object
    },
    NativeComponentSantaTypes: {
        publicData: PropTypes.object,
        sectionUrls: PropTypes.arrayOf(PropTypes.object),
        widgetStyle: PropTypes.object,
        instance: PropTypes.string,
        resizeComponent: PropTypes.func,
        viewMode: PropTypes.string,
        deviceType: PropTypes.string,
        appLoadBI: PropTypes.object
    },
    ColumnsContainer: {
        childrenData: columnsContainerChildren
    },
    Fonts: {
        fontsMap: PropTypes.array
    },
    Popover: {
        open: PropTypes.func,
        close: PropTypes.func,
        rootContentStyle: PropTypes.object,
        reportMouseIn: PropTypes.func,
        reportMouseOut: PropTypes.func,
        targetBounds: PropTypes.object,
        targetPortal: PropTypes.object
    },
    DocumentClickEvent: {
        registerToDocumentClickEvent: PropTypes.func,
        unRegisterToDocumentClickEvent: PropTypes.func
    },
    Container: {
        defaultBackgroundStyle: PropTypes.object,
        defaultContentArea: PropTypes.object
    },
    Component: {
        isCollapsed: PropTypes.bool,
        isHorizontallyDocked: PropTypes.bool,
        id: PropTypes.string,
        refInParent: PropTypes.string,
        rootId: PropTypes.string,
        pageId: PropTypes.string,
        compActions: PropTypes.object,
        structure: PropTypes.shape({
            componentType: PropTypes.string.isRequired,
            dataQuery: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            propertyQuery: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            designQuery: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            behaviorQuery: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object
            ]),
            components: PropTypes.array,
            skin: PropTypes.string,
            styleId: PropTypes.string,
            id: PropTypes.string,
            type: PropTypes.string
        }),
        styleParam: {
            textAlignment: PropTypes.string,
            colorScheme: PropTypes.string
        },
        childrenLayout: PropTypes.object,
        childrenStyle: PropTypes.object,
        fixedChildrenIDs: PropTypes.array,
        rootNavigationInfo: PropTypes.shape({
            pageId: PropTypes.string.isRequired,
            title: PropTypes.string,
            pageAdditionalData: PropTypes.string,
            pageItemId: PropTypes.string,
            pageItemAdditionalData: PropTypes.string,
            transition: PropTypes.string,
            anchorData: PropTypes.string
        }),
        currentUrlPageId: PropTypes.string,
        pageStub: PropTypes.bool,
        styleId: PropTypes.string,
        skin: PropTypes.string,
        getStyleData: PropTypes.func,
        compProp: PropTypes.object,
        compData: PropTypes.object,
        compDesign: PropTypes.object,
        renderFixedPosition: PropTypes.bool,
        style: styleShape,
        screenWidthStyle: styleShape,
        meshParams: PropTypes.object,
        rotationInDegrees: PropTypes.number,
        scale: PropTypes.number,
        key: PropTypes.string,
        ref: PropTypes.string,
        currentUrlPageTitle: PropTypes.string,
        dimensions: PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired
        }),
        compStaticBehaviors: PropTypes.array,
        theme: PropTypes.object,
        compBehaviors: PropTypes.array,
        trackBehaviorsToExecute: PropTypes.func,
        isHiddenOnStart: PropTypes.bool,
        shouldHideAnimatable: PropTypes.bool
    },
    Theme: {
        all: PropTypes.object,
        colors: PropTypes.array,
        colorsMap: PropTypes.array,
        THEME_DATA: PropTypes.object
    },
    Behaviors: {
        handleAction: PropTypes.func,
        registerBehaviors: PropTypes.func,
        setBehaviorsForActions: PropTypes.func,
        convertBehaviors: PropTypes.func
    },
    Layout: {
        reLayoutIfPending: PropTypes.func,
        registerReLayoutPending: PropTypes.func,
        registerLayoutFunc: PropTypes.func,
        isMeshLayoutMechanism: PropTypes.bool
    },
    Utils: {
        logging: {
            performance: {
                now: PropTypes.func
            }
        },
        logger: PropTypes.shape({
            error: PropTypes.func.isRequired
        })
    },
    Device: {
        isTouchDevice: PropTypes.bool,
        isMobileDevice: PropTypes.bool,
        devicePixelRatio: PropTypes.number
    },
    Mobile: {
        cannotHideIframeWithinRoundedCorners: PropTypes.bool,
        siteZoomRatio: PropTypes.number,
        invertedZoomRatio: PropTypes.number,
        orientationZoomFix: PropTypes.number,
        mobileZoom: PropTypes.number
    },
    RenderFlags: {
        componentPreviewState: PropTypes.string,
        componentViewMode: PropTypes.string,
        renderFixedPositionContainers: PropTypes.bool,
        shouldResetComponent: PropTypes.bool,
        isPlayingAllowed: PropTypes.bool
    },
    RenderRealtimeConfig: {
        previewTooltipCallback: PropTypes.func,
        shouldHideTextComponent: PropTypes.bool,
        shouldHideComponent: PropTypes.bool,
        shouldShowComponentOnTop: PropTypes.bool,
        componentOpacity: PropTypes.number
    },
    RawSvg: {
        getRawSVG: PropTypes.string
    },
    Animations: {
        animationProperties: PropTypes.object
    },
    ServiceTopology: {
        scriptsDomainUrl: PropTypes.string,
        staticMediaUrl: PropTypes.string,
        scriptsLocationMap: PropTypes.object,
        getMediaFullStaticUrl: PropTypes.func
    },
    Browser: {
        browser: PropTypes.object
    },
    BrowserFlags: {
        cssFiltersSupported: PropTypes.bool
    },
    Images: {
        onImageUnmount: PropTypes.func
    },
    __DangerousSantaTypes: {
        getRenderedMasterPageHeight: PropTypes.func,
        getWindowSize: PropTypes.func
    },
    DAL: {
        setCompState: PropTypes.func,
        setCompData: PropTypes.func,
        setCompProps: PropTypes.func,
        removeCompState: PropTypes.func
    },
    PageGroup: {
        pagesToRender: PropTypes.array,
        createPageProps: PropTypes.func,
        stubifyPage: PropTypes.func
    },
    Repeater: {
        templateLayout: PropTypes.shape({
            repeaterWidth: PropTypes.number,
            templateWidth: PropTypes.number,
            docked: PropTypes.shape({
                left: dockMarginShape, top: dockMarginShape, right: dockMarginShape, bottom: dockMarginShape
            }),
            itemCount: PropTypes.number
        })
    },
    SiteButton: {
        link: PropTypes.object,
        impliedLink: PropTypes.object
    },
    Link: {
        renderInfo: PropTypes.shape({
        // TODO: KADURI - split these to own object
            primaryPageId: PropTypes.string,
            currentUrl: PropTypes.object,
            currentUrlPageId: PropTypes.string,

            // TODO: KADURI - split these to own object
            urlFormat: PropTypes.string,
            mainPageId: PropTypes.string,
            externalBaseUrl: PropTypes.string,
            unicodeExternalBaseUrl: PropTypes.string,
            publicBaseUrl: PropTypes.string,
            isFeedbackEndpoint: PropTypes.bool,
            isSiteHistoryEndpoint: PropTypes.bool,
            isViewerMode: PropTypes.bool,
            isWixSite: PropTypes.bool,
            isTemplate: PropTypes.bool,
            isUsingSlashUrlFormat: PropTypes.bool,
            isPremiumDomain: PropTypes.bool,
            serviceTopology: PropTypes.shape({
                staticDocsUrl: PropTypes.string,
                basePublicUrl: PropTypes.string,
                baseDomain: PropTypes.string
            }),

            // TODO: KADURI - split these to own object
            routersConfigMap: PropTypes.object,
            allPageIds: PropTypes.array,
            pagesDataItemsMap: PropTypes.object,
            mapFromPageUriSeoToPageId: PropTypes.object,
            permalinksMap: PropTypes.object,
            rootNavigationInfo: PropTypes.object
        })
    },
    WixAds: {
        wixTopAdsHeight: PropTypes.number
    },
    WixUserSantaTypes: {
        userLanguage: PropTypes.string
    }
})
