'use strict'

const FormContainer = require('./containers/FormContainer')

const containerFactory = require('./containers/containerFactory')
const LegacyContainer = containerFactory({legacyInlineContent: true, includeScrollMixin: true})
const GroupContainer = containerFactory({legacyInlineContent: false, includeScrollMixin: false, displayName: 'WixGroupContainer'})
const Container = containerFactory({legacyInlineContent: false, includeScrollMixin: true})
const AppWidget = containerFactory({legacyInlineContent: false, includeScrollMixin: true, displayName: 'AppWidget'})

module.exports = {
    FormContainer,
    GroupContainer,
    LegacyContainer,
    Container,
    AppWidget
}
