'use strict'

const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')

module.exports = ({legacyInlineContent = false, displayName = 'WixContainer', includeScrollMixin = false}) => createReactClass({
    displayName,

    mixins: [
        ...includeScrollMixin ? [scrollMixin] : [], 
        skinBasedComp(skinsJson), 
        ...legacyInlineContent ? [] : [inlineContentMixin]
    ],

    statics: {
        getComponentSkins: () => skinsJson
    },

    getSkinProperties() {
        return {
            inlineContent: {
                children: legacyInlineContent ? this.props.children : this.getChildrenRenderer()
            }
        }
    }
})
