'use strict'

const _ = require('lodash')
const components = require('./components/components')
const animatableMixin = require('./mixins/animatableMixin')
const animationsMixin = require('./mixins/animationsMixin')
const buttonMixin = require('./mixins/buttonMixin')
const compActionMixin = require('./mixins/compActionMixin')
const baseCompMixin = require('./mixins/baseCompMixin')
const inlineContentMixin = require('./mixins/inlineContentMixin')
const fixedPositionContainerMixin = require('./mixins/fixedPositionContainerMixin')
const skinInfoMixin = require('./mixins/skinInfoMixin')
const animationsQueueHandler = require('./utils/animationsQueueHandler')
const createChildComponentUtils = require('./utils/createChildComponentUtils')
const fixInvalidStyles = require('./utils/fixInvalidStyles')
const collectFontsFromLoadedCompStyles = require('./utils/skins/collectFontsFromLoadedCompStyles')
const skinRenderer = require('./utils/skins/skinRenderer')
const withBehaviors = require('./HOC/withBehaviors')
const scrollMixin = require('./mixins/scrollMixin/scrollMixin')

const infra = require('./infra')

module.exports = _.merge({}, infra, {
    components,
    mixins: {
        animatableMixin,
        animationsMixin,
        buttonMixin,
        compActionMixin,
        baseCompMixin: baseCompMixin.baseComp,
        inlineContentMixin,
        fixedPositionContainerMixin,
        skinInfoMixin,
        scrollMixin
    },
    utils: {
        skinsRenderer: skinRenderer,
        collectFontsFromLoadedCompStyles,
        animationsQueueHandler,
        createChildComponentUtils,
        fixInvalidStyles
    },
    HOC: {
        withBehaviors
    }
})
