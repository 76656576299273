
'use strict'
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')

module.exports = createReactClass({
    displayName: 'FormContainer',
    mixins: [skinBasedComp(skinsJson), scrollMixin, inlineContentMixin],
    getSkinProperties() {
        const preventSubmit = e => e.preventDefault()
        return {
            '': {
                style: {}
            },
            form: {
                onSubmit: preventSubmit,
                children: this.getChildrenRenderer({contentSkinPartId: 'form'})
            }
        }
    }
})
