'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')
const childrenRenderer = require('../components/ChildrenRenderer/ChildrenRenderer')

module.exports = {
    statics: {
        meshContainer: true
    },

    propTypes: {
        id: santaTypesDefinitions.Component.id,
        fixedChildrenIDs: santaTypesDefinitions.Component.fixedChildrenIDs,
        meshParams: santaTypesDefinitions.Component.meshParams,
        isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
        isPreviewMode: santaTypesDefinitions.isPreviewMode,
        browser: santaTypesDefinitions.Browser.browser,
        isMobileView: santaTypesDefinitions.isMobileView
    },

    ignoreDimensions() {
        return this.props.isMeshLayoutMechanism
    },

    getChildrenRenderer({overrides, contentArea, filterChildren, contentSkinPartId, children} = {}) {
        const {id, meshParams, isPreviewMode, isMobileView, children: propsChildren, isMeshLayoutMechanism, fixedChildrenIDs, browser} = this.props

        const props = {
            filterChildren,
            isMeshLayoutMechanism,
            isPreviewMode,
            isMobileView,
            browser,
            contentArea,
            fixedChildrenIDs,
            id,
            adjustingId: meshParams.adjustingComp,
            container: _.assign(_.omit(meshParams, 'adjustingComp'), overrides),
            children: children || propsChildren || [],
            contentSkinPartId
        }

        return childrenRenderer(props)
    }
}
