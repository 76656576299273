'use strict'

const _ = require('lodash')
const createReactClass = require('create-react-class')
const inlineContentMixin = require('../../mixins/inlineContentMixin')
const createReactElement = require('../../utils/createReactElement')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')

const MASTER_PAGE_PROPS_NOT_IN_DOM = ['fixedChildrenIDs', 'siteWidth', 'isPreviewMode', 'meshParams',
    'isMeshLayoutMechanism', 'isMobileView', 'compBehaviors', 'componentViewMode', 'defaultContentArea', 'isInSSR', 'childrenLayout', 'isCurrentPageLandingPage']

const getPlaceholderStyle = (compLayout, isCurrentPageLandingPage, isInSSR) => {
    const placeholderStyle = {
        height: isInSSR ? compLayout.height : 0
    }

    if (isCurrentPageLandingPage) {
        placeholderStyle.display = 'none'
    }

    return placeholderStyle
}

const masterPage = {
    displayName: 'WixMasterPage',
    mixins: [inlineContentMixin],
    propTypes: {
        childrenLayout: santaTypesDefinitions.Component.childrenLayout,
        compBehaviors: santaTypesDefinitions.Component.compBehaviors,
        componentViewMode: santaTypesDefinitions.RenderFlags.componentViewMode.isRequired, // hack to get page behaviors to re-register when switching to preview
        defaultContentArea: santaTypesDefinitions.Container.defaultContentArea.isRequired,
        fixedChildrenIDs: santaTypesDefinitions.Component.fixedChildrenIDs,
        isInSSR: santaTypesDefinitions.isInSSR.isRequired,
        isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
        isMobileView: santaTypesDefinitions.isMobileView,
        isCurrentPageLandingPage: santaTypesDefinitions.isCurrentPageLandingPage,
        skin: santaTypesDefinitions.Component.skin,
        style: santaTypesDefinitions.Component.style.isRequired
    },

    renderMesh() {
        const {children, isMobileView, fixedChildrenIDs, isInSSR, childrenLayout, isCurrentPageLandingPage} = this.props
        const structuralIDs = isMobileView ?
            {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true, SOSP_CONTAINER_CUSTOM_ID: true} :
            {SITE_HEADER: true, SITE_FOOTER: true, PAGES_CONTAINER: true, BACK_TO_TOP_BUTTON: true}
        const getChildStructureLayout = childId => childrenLayout[childId] || {}
        const createPlaceholder = id => {
            if (!_.includes(fixedChildrenIDs, id)) {
                return null
            }

            return createReactElement('div', {key: `${id}-placeholder`, id: `${id}-placeholder`, style: getPlaceholderStyle(getChildStructureLayout(id), isCurrentPageLandingPage, isInSSR)})
        }
        const isStructural = id => !!structuralIDs[id]
        const partitioned = _.partition(children, ({props: p}) => isStructural(p.id))
        const structuralChildren =
            _(partitioned[0])
                .flatMap(p => [p, createPlaceholder(p.props.id)])
                .compact()
                .value()

        const inlineChildren = partitioned[1]

        const hasSOAP = !_.isEmpty(inlineChildren)

        if (!hasSOAP) {
            return createReactElement('div',
                {id: 'masterPage', className: `${isMobileView ? 'mobile' : 'desktop'} mesh-layout`, 'data-mesh-layout': 'flex'},
                _.concat(structuralChildren, inlineChildren))
        }

        return createReactElement('div',
            {id: 'masterPage', className: 'mesh-layout', 'data-mesh-layout': 'grid'},
            [...structuralChildren, this.getChildrenRenderer({
                contentArea: this.props.defaultContentArea,
                filterChildren: id => !isStructural(id),
                overrides: {fitToContentHeight: true}
            })])
    },

    render() {
        return this.props.isMeshLayoutMechanism ?
            this.renderMesh() :
            createReactElement('div', _.omit(this.props || {}, MASTER_PAGE_PROPS_NOT_IN_DOM),
                this.getChildrenRenderer({contentArea: this.props.defaultContentArea}))
    }
}

module.exports = createReactClass(masterPage)